.main-logo {
    max-height: 40%;
    max-width: 40%;
    padding-top: 20px;
  }
  
  @media only screen and (max-width: 500px) {
    .main-logo {
        max-height: 100%;
        max-width: 100%;
        padding-top: 0;
    }
  }