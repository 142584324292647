/* header.css */
header {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/* Mobile */
/* @media (max-width: 1023px) {
  header {
      background-image: url('/Assets/Mobile/FooterBarMob.jpg');
      height: 1rem ;
}
} */

/* Desktop */
/* @media (min-width: 1024px) {
    header {
        background-image: url('/Assets/Desktop/FooterBar.jpg');
        height: 1rem ;
  }
} */
