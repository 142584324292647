@tailwind base;
@tailwind components;
@tailwind utilities;

/* Default background for desktop */
html {
    /* background-image: url('../public/Assets/BSH/Dishwasher/Apr24/Desktop/LandingBackground.png');  */
    background-size: cover;
    background-repeat: no-repeat;
    /* background-color: black; */
}
.custom-textbox {
    @apply border-blue-500 bg-blue-100 focus:ring-blue-500 focus:border-blue-500;
}
.btnPrimaryEnabled {
    @apply 
        bg-primary 
        p-4 
        flex 
        items-center 
        justify-center 
      text-white
        font-bold
        rounded-2xl
        tracking-wide
        border-4
        border-transparent 
        duration-300
      active:border-black
      active:text-black
}

.btnPrimaryDisabled{
    @apply 
        bg-primary 
        p-4 
        flex 
        items-center 
        justify-center 
        text-white 
        font-bold 
        rounded-2xl 
        tracking-wide 
        border-4 
        border-transparent 
        duration-300 
        opacity-50 
        cursor-not-allowed
}

.btnSecondaryEnabled {
    @apply 
        bg-secondary
        p-4 
        flex 
        items-center 
        justify-center 
        text-white 
        font-bold 
        rounded-2xl 
        tracking-wide 
        border-4 
        border-transparent 
        duration-300
}


.btnLinkEnabled {
    background: none!important;
    border: none;
    padding: 0!important;
    color: black;
    text-decoration: underline;
    cursor: pointer;
    @apply
        font-bold
}

.btnLinkDisabled {
    background: none!important;
    border: none;
    padding: 0!important;
    text-decoration: underline;
    cursor: not-allowed;
    @apply 
        text-gray-300 
}

.btnSecondaryDisabled {
    @apply 
        bg-secondary 
        p-4 
        flex 
        items-center 
        justify-center 
        text-white 
        font-bold 
        rounded-2xl 
        tracking-wide 
        border-4 
        border-transparent 
        duration-300
        opacity-50 
        cursor-not-allowed
}


.Exclamation{
    @apply 
        text-primary 
        text-7xl
        mb-6
}

.StageHeading{
    @apply 
        text-2xl 
        font-bold 
        text-primary 
        mb-4 
        text-center 
        md:text-2xl
}

.StageSubHeading{
    @apply 
        text-gray-500 
        text-left 
        font-light 
        text-base
}

.DateControl{
    -webkit-appearance: none;
    @apply 
        shadow 
        border-gray-300 
        rounded 
        w-full 
        py-2 
        px-3 
        text-gray-700 
        leading-tight 
        focus:outline-none 
        focus:ring 
        focus:border-blue-300
}

.ErrorText{
    @apply 
        text-red-500 
        text-xs 
        font-light 
        text-left 
        mt-2
        italic
        min-h-4
}

/* For webkit browsers like Chrome, Safari */
input[type="number"]::-webkit-inner-spin-button, 
input[type="number"]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

/* For Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
